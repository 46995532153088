import React, { useContext, useEffect, useState } from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import { renderRichText } from "gatsby-source-contentful/rich-text"
import slugify from 'slugify';


import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemButton from '../ListItem';
import Avatar from '@material-ui/core/Avatar';
import ExtensionIcon from '@material-ui/icons/Extension';
import GroupIcon from '@material-ui/icons/Group';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FaceIcon from '@material-ui/icons/Face';
// import Img from 'gatsby-image';
import Button from '../Button';
import { getParts } from '../../lib/routing';
import SellBadge, { isForSale } from './SellBadge';
// import { imageToDisplay } from '../GameCard';
import GameAnalysis from './GameAnalysis';
import RelatedGames from './RelatedGames'
import CardSizes from './CardSizes'
import GameImagesCarousel from './GameImagesCarousel';
import { UserGamesContext } from '../GamesLayout'
import useLovedGame, { Love } from '../../hooks/useLovedGame'
import usePlayedGame, { Played } from '../../hooks/usePlayedGame'
import useWantToPlayGame, { WantToPlay } from '../../hooks/useWantToPlayGame'
import { FirebaseFirestoreContext } from '../GamesLayout';
import { FirebaseUserContext } from '../GamesLayout';
import { GetStreamClientContext } from '../GamesLayout';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

// const getCity = (city) => (city.contentful_id || city);

const TagsForGame = (props) => {
  const tags = props.game.gameTags || [];
  if (tags.length === 0) return null;
  return (
    <Box pt={1}>
      <Typography variant="body1" gutterBottom>
        Etiquetas
      </Typography>
      <React.Fragment>
      { tags.map((tag) => {
          const slug = (tag.slug) ? tag.slug : slugify(tag.name, {lower: true, strict: true});
          // const city = getCity(props.city);
          const tagUrl = `/ba/tags/${tag.contentful_id}/${slug}`;
          return (
            <Button key={tag.contentful_id} size="small" variant="contained" color="secondary" to={tagUrl}>{tag.name}</Button>
          )
        })
      }
      </React.Fragment>
    </Box>
  );
}

const expands = ({classes, city, game}) => (expa) => {
  const resource = (expa.expansion) ? 'expansion' : 'juego';
  const baseUrl = `/ba/${resource}/${expa.gameId}/${expa.slug}`;
  return (
    <ListItemButton button className={classes.nested} key={expa.id} to={baseUrl}>
    <React.Fragment>
      <ListItemText primary={expa.title} />
    </React.Fragment>
  </ListItemButton>
  )
}

const GameExpands = (props) => {
  const expandsList = (props.game.expands || [])
  if (expandsList.length === 0) return null;
  return (
    <React.Fragment>
      <Divider />

      <Typography variant="body1">
        Expande
      </Typography>
      <List key="expands" className={props.classes.root}>
      <ListItem>
        <ListItemIcon>
          <ExtensionIcon />
        </ListItemIcon>
        <List component="div">
          {expandsList.map(expands(props))}
        </List>
      </ListItem>
    </List>

    </React.Fragment>
  )
}

const GameExpansions = (props) => {
  const expansions = (props.game.expansions || []);
  if (expansions.length === 0) return null;
  return (
    <React.Fragment>
      <Divider />

      <Typography variant="body1">
        Expansiones
      </Typography>
      <List key="expansions" className={props.classes.root}>
        <ListItem>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <List component="div">
            {expansions.map((expa) => {
              const resource = (expa.expansion) ? 'expansion' : 'juego';
              const baseUrl = `/ba/${resource}/${expa.gameId}/${expa.slug}`;
              return (
                <ListItemButton button className={props.classes.nested} key={expa.id} to={baseUrl}>
                  <React.Fragment key={expa.id}>
                    <ListItemText primary={expa.title} />
                  </React.Fragment>
                </ListItemButton>
              );
            })}
          </List>
        </ListItem>
      </List>
    </React.Fragment>

  ) 
}

// const AdaptedGameImage = (props) => {
//   const { game } = props;
//   const image = imageToDisplay(game);
//   return(
//     <React.Fragment>
//     {image && 
//         <Img
//           fluid={image} 
//           alt={game.title} 
//           loading="lazy"
//           placeholderStyle={{ backgroundColor: `white` }}
//         />
//     }

//     </React.Fragment>
//   )
// }

export const Game = (props) => {
  const classes = useStyles();
  const [viewed, setViewed] = useState();
  const userGames = useContext(UserGamesContext)
  const firestore = useContext(FirebaseFirestoreContext);

  const userContext = useContext(FirebaseUserContext);
  const getStreamClient = useContext(GetStreamClientContext)

  const { game, plainDescription } = props;
  const { lovedState, addFavorite } = useLovedGame(firestore, userContext.user, userGames, game.gameId)
  const { playedState, addPlayed } = usePlayedGame(firestore, userContext.user, userGames, game.gameId)
  const { wantToPlayState, addWantToPlay } = useWantToPlayGame(firestore, userContext.user, userGames, game.gameId)
  const gameWithPlainDescription = {
    ...game,
    plainDescription,
  }
  const authorsList = game.authors || []

  // const authors = authorsList.map((author) => author.name).join(', ');
  // const expansions = (game.expansions || []);
  // const commingSoon = (game.avaliable) ? '' : 'próximamente';
  const title = isForSale(game) ? game.game_for_sale[0].title : game.title;
  const description = {
    ...JSON.parse(game.description.raw),
    references: []
  }
  
  useEffect(() => {
    // console.log('getStreamClient', getStreamClient)
    // console.log('getStreamClient.client', getStreamClient.client)
    // console.log('getStreamClient.addGameActivity', getStreamClient.addGameActivity)
    // console.log('viewed', viewed)
    if (getStreamClient && getStreamClient.client && getStreamClient.addGameActivity && !viewed) {
      console.log('call')
      const ref = getStreamClient.client.collections.entry('boardgame', game.gameId); 
      console.log('ref', ref)
      getStreamClient.addGameActivity('view', ref)
      setViewed(true)
    }
  }, [game.gameId, getStreamClient, viewed])
  const isAdmin = userContext && userContext.userRole && userContext.userRole === 'admin'
    return (
      <Grid container justify="center">
      <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
        <Paper elevation={3}>
          {/* <AdaptedGameImage game={game} /> */}
          <GameImagesCarousel game={game} />
          <Box m={2} p={2}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <WantToPlay wantToPlay={wantToPlayState} add={addWantToPlay(wantToPlayState)} />
            <Played played={playedState} addFavorite={addPlayed(playedState)} />
            <Love loved={lovedState} addFavorite={addFavorite(lovedState)} />
            {isAdmin && <>
            admin
            </>}
            <SellBadge game={gameWithPlainDescription} />
            <Typography variant="body1" color="textSecondary" component="span">
              {documentToReactComponents(description)}
            </Typography>
            <Divider />
            <List className={classes.root}>
              <ListItem key="players">
                <ListItemAvatar>
                  <Avatar>
                    <GroupIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Jugadores" secondary={`${game.minimumPlayers}-${game.maximumPlayers}`} />
              </ListItem>
              <ListItem key="duration">
                <ListItemAvatar>
                  <Avatar>
                    <ScheduleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Duración" secondary={`${game.minimumPlayingTime}-${game.maximumPlayingTime}`} />
              </ListItem>
              <ListItem key="designer">
                <ListItemAvatar>
                  <Avatar>
                    <FaceIcon />
                  </Avatar>
                </ListItemAvatar>
                {authorsList.length > 0 &&
                  <React.Fragment>
                    <Typography variant="body1" gutterBottom>
                      {(authorsList.length > 1) ? 'Autores' : 'Autor'}
                    </Typography>
          
                    {authorsList.map((author) => {
                      return (
                        <ListItemButton key={author.contentful_id} button to={`/autor/${author.contentful_id}/${author.slug}`}>
                          <ListItemText primary={author.name} />
                        </ListItemButton>
                      )
                    })}
                </React.Fragment>
              }
            </ListItem>
              <ListItem key="year">
                <ListItemAvatar>
                  <Avatar>
                    <CalendarTodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Año" secondary={game.year} />
              </ListItem>
            </List>

            <GameAnalysis game={game} classes={classes} />

            <GameExpansions game={game} classes={classes} />
            <GameExpands game={game} classes={classes} />
            <Divider />
            <CardSizes game={game} />
            <Divider />
            <RelatedGames game={game} />
            <Divider />
            <TagsForGame game={game} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )

}

export default Game;
