import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  sizeTitle: {
    paddingTop: theme.spacing(0.5),
    textAlign: 'center',
  }
}));

const colors = [
  '#ffd700',
  '#ffdead',
  '#e1ffad',
  '#adffe8',
]

const translations = {
  title: 'Usa fundas tamaño'
}

const CardSizes = (props) => {
  const { game } = props
  const components = game.components
  const cardSizes = components?.cards
  if (!cardSizes || !cardSizes.length || !cardSizes.length === 0) return null
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" gutterBottom>{translations.title}</Typography>
      </Grid>
      <Grid container alignItems="flex-start" direction="row">
          {
            cardSizes.map((size, index) => {
              return (
                <div key={size.name} style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', padding: 2}}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #666', borderRadius: 5, width: size.width / 5, height: size.height / 5, backgroundColor: colors[index], boxShadow: '3px 3px 1px #ddd'}}>
                    <div className={classes.sizeTitle}>
                      <Typography variant="body2" align="center">{size.name}</Typography>
                    </div>
                    <div className={classes.sizeTitle}>
                      <Typography variant="body1" align="center"><strong>{size.number} cartas</strong></Typography>
                    </div>
                  </div>
                </div>
              )
            })
        }

      </Grid>
    </Grid>

  );
}

export default CardSizes;

