import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import Img from 'gatsby-image';
import { GatsbyImage } from "gatsby-plugin-image"

import { Typography } from '@material-ui/core';
import { Link } from '../Link'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  gameTitle: {
    paddingTop: theme.spacing(0.5),
  }
}));

const translations = {
  title: 'Juegos relacionados'
}

const RelatedGames = (props) => {
  const { game } = props
  const games = game.relatedGames
  if (!games || !games.length || !games.length === 0) return null
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" gutterBottom>{translations.title}</Typography>
      </Grid>
      {
        games.map((game, index) => {
          return (
            <Grid item key={`${game.gameId}-${index}`} xl={3} lg={3} md={4} sm={6} xs={6}>
              {/* <Link to={`/${city}/juego/${game.gameId}/${game.slug}`}> */}
              <Link to={`/ba/juego/${game.gameId}/${game.slug}`}>
                <div>
                  {/* <Img fluid={game.image.fluid} /> */}
                  <GatsbyImage image={game.image.gatsbyImageData} alt={game.title || 'Juego de mesa'}/>
                </div>
                <div className={classes.gameTitle}>
                <Typography variant="body2" align="center">{game.title}</Typography>
                </div>
              </Link>
            </Grid>
            )
        })
      }
    </Grid>

  );
}

export default RelatedGames;

