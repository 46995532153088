import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: 'solid 1px #ccc',
    borderBottom: 'solid 1px #ccc',
  },
}));

const rangeStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '50%',
  },
  labelStart: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
    justifyContent: 'flex-start',

  },
  labelEnd: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
    justifyContent: 'flex-end',

  },
  rank: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    flexGrow: 1,
  },
  item: {
    flexGrow: 1,
    flexBasis: '100%',
    width: '100%',
    // maxWidth: 200,
    border: '2px solid #ddd'
  }
}));

const getColor = (number) => {
  switch (number) {
    case 1:
      return '#fd0'
    case 2:
      return '#f00'
    default:
      return '#eee'
  }
}


const Range = props => {
  const { rank, notes, start, end } = props
  const classes = rangeStyles()
  return (
    <div className={classes.container}>
      <div className={classes.labels}>
        <div className={classes.labelStart}><Typography>{start}</Typography></div>
        <div className={classes.labelEnd}><Typography>{end}</Typography></div>
      </div>
      <div className={classes.rank}>
      {rank && rank.split('').map((r, index) => {
        const color = getColor(parseInt(r, 10))
        return (
          <div key={index} className={classes.item}>
            <div style={{ backgroundColor: color, height: 8 }} >&nbsp;</div>
          </div>
        )
      })}
      </div>
    </div>
  )
}

const GameAnalysis = props => {
  const { game } = props
  const classes = useStyles()
  const { familyAdvanced, luckStrategy, quietInteractive } = game
  if (!familyAdvanced && !luckStrategy && !quietInteractive) return null
  return (
    <div className={classes.container}>
      <div>
        <Typography component="h6" variant="h6" gutterBottom>Análisis del Juego</Typography>
      </div>
      {familyAdvanced && <Range {...familyAdvanced} start="Familia" end="Avanzado" />}
      {luckStrategy && <Range {...luckStrategy} start="Suerte" end="Estrategia" />}
      {quietInteractive && <Range {...quietInteractive} start="Tranquilo" end="Interactivo" />}
    </div>
  )
}

export default GameAnalysis;